$('.addAnimation-custom, .addAnimation').each(function () {
  var window_height = $(window).height();
  var imagePos = $(this).offset().top;
  var topOfWindow = $(window).scrollTop();
  var animation_name = $(this).attr('data-animation');

  if (window_height > imagePos) {
    $(this).addClass(animation_name);
  }
});

$('.addAnimationSteps').each(function () {
  var window_height = $(window).height();
  var imagePos = $(this).offset().top;
  var topOfWindow = $(window).scrollTop();

  var speed2 = $(this).attr('data-speed');
  if (speed2 == null) {
    var speed = 350;
  } else {
    var speed = speed2;
  }


  var element = $(this);

  if (window_height > imagePos) {
    $(element).find('.animate-this').each(function (i) {
      setTimeout(function () {
        $(element).find('.animate-this').eq(i).addClass("active");
      }, speed * i);
    });

    $(element).find('.addAnimationStep').each(function (i) {
      var animation = $(this).attr('data-animation');
      setTimeout(function () {
        $(element).find('.addAnimationStep').eq(i).addClass(animation);
      }, speed * i);
    });
  }
});


$(window).scroll(function () {

  $('.addAnimation-custom, .addAnimation').each(function () {
    var window_height = $(window).height();
    var imagePos = $(this).offset().top;
    var topOfWindow = $(window).scrollTop();
    var animation_name = $(this).attr('data-animation');

    // if (window_height < imagePos) {
    if (imagePos < topOfWindow + $(window).height() / 1.4) {
      $(this).addClass(animation_name);
    }
    // }
  });


  $('.addAnimationSteps').each(function () {
    var window_height = $(window).height();
    var imagePos = $(this).offset().top;
    var topOfWindow = $(window).scrollTop();

    var speed2 = $(this).attr('data-speed');
    if (speed2 == null) {
      var speed = 350;
    } else {
      var speed = speed2;
    }

    var element = $(this);

    if (window_height < imagePos) {
      if (imagePos < topOfWindow + $(window).height() / 1.4) {
        $(element).find('.animate-this').each(function (i) {
          setTimeout(function () {
            $(element).find('.animate-this').eq(i).addClass("active");
          }, speed * i);
        });

        $(element).find('.addAnimationStep').each(function (i) {
          var animation = $(this).attr('data-animation');
          setTimeout(function () {
            $(element).find('.addAnimationStep').eq(i).addClass(animation);
          }, speed * i);
        });
      };
    }
  });

});